<template>
	<div v-if="dialogVisible">
		<el-dialog title="收文详情" :visible.sync="dialogVisible" width="60%">
			<div class="addbox" ref="printbox">
				<div class="left_info">
					<div class="mytabble" style="width: 100%;">
						<div class="innerbox">
							<div class="title">
								政协宁夏委员办公室公文处理单
							</div>
							<div class="tablebox">
								<div class="line">
									<div class="namebox">
										<span>来文单位</span>					
									</div>
									<div class="value" style="width: 51%;">
										<span>{{info.lydw}}</span>
									</div>
									<div class="namebox">
										<span>收文日期</span>					
									</div>
									<div class="value" style="width: 25%;">
										<span>{{info.swrq}}</span>
									</div>
								</div>
								<div class="line">
									<div class="namebox">
										<span>份数</span>					
									</div>
									<div class="value" style="width: 12%;">
										<span>{{info.fen}}</span>
									</div>
									<div class="namebox" style="width: 13.6%;">
										来文字号
									</div>
									<div class="value" style="width: 12%;border-right: none;">
										<span>{{info.lwzh}}</span>
									</div>
									<div class="namebox" style="width: 13.4%;">
										收文字号
									</div>
									<div class="value" style="width: 12.0%;border-right: none;">
										<span>{{info.swzh}}</span>
									</div>
									<div class="namebox" style="width: 10%;">
										密级
									</div>
									<div class="value" style="width: 15%;">
										<span>{{info.mj}}</span>
									</div>
								</div>
								<div class="line" style="height: 75px;">
									<div class="namebox" style="width: 18%;">
										<span>文件标题</span>					
									</div>
									<div class="value" style="width: 82%;border-right: 1px solid #333;text-align: left;">
										<span>{{info.name}}</span>
									</div>
								</div>
								<div class="line" style="min-height: 120px;height: auto;border-left: 1px solid #333;border-top: 1px solid #333;">
									<div class="namebox" style="width: 17.9%;border-left: none;border-top: none;min-height: 120px;">
										<span>拟办意见</span>					
									</div>
									<div class="value"
										style="width: 82.1%;border-right: 1px solid #333;display: flex;border-top: none;min-height: 120px;justify-content: flex-start;align-items: center;">
										<div style="padding: 10px 0;margin: 10px 0;max-width: 75%;margin-right: 5%;">
											{{info.nbyj}}											
										</div>		
										<img v-show="info.type>1" :src="info.ybuse_url" style="width: 20%;height: auto;" alt="" />
									</div>
								</div>
								<div class="line" style="min-height: 200px;height: auto;border-left: 1px solid #333;border-top: 1px solid #333;">
									<div class="namebox" style="width: 17.9%;border-left: none;border-top: none;min-height: 200px;">
										<span>领导批示</span>					
									</div>
									<div class="value" style="width: 82.1%;border-right: 1px solid #333;display: flex;flex-direction: column;border-top: none;min-height: 200px;justify-content: flex-start;align-items: baseline;">
										<div v-for="(item,index) in info.ps" :key="index" style="padding: 10px 0;margin: 10px 0;display: flex;align-items: center;">
											<div style="max-width: 75%;margin-right: 5%;">
												{{item.pswz}}							
											</div>		
											<img :src="item.qzurl" style="width: 20%;height: auto;" alt="" />
										</div>
										<div v-for="(item,index) in info.wps" :key="index" style="padding: 10px 0;margin: 10px 0;display: flex;align-items: center;">
											<div style="max-width: 75%;margin-right: 5%;">
												{{item.pswz}}							
											</div>		
											<img :src="item.qzurl" style="width: 20%;height: auto;" alt="" />
										</div>
									</div>
								</div>
								<div class="line" style="min-height: 200px;height: auto;border-left: 1px solid #333;border-top: 1px solid #333;">
									<div class="namebox" style="width: 17.9%;border-left: none;border-top: none;min-height: 200px;">
										<span>办理结果</span>					
									</div>
									<div class="value" style="width: 82.1%;border-right: 1px solid #333;display: flex;flex-direction: column;border-top: none;min-height: 200px;justify-content: flex-start;align-items: baseline;">
										<div v-for="(item,index) in info.cb" :key="index" style="padding: 10px 0;margin: 10px 0;display: flex;align-items: center;">
											<div style="max-width: 75%;margin-right: 5%;">
												{{item.nljg}}							
											</div>		
											<img :src="item.qzurl" style="width: 20%;height: auto;" alt="" />
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>	
					<!-- <div class="title">
						正文
					</div>
					<div v-html="info.zhengwen" style="padding: 15px;line-height: 25px;">
						
					</div> -->
					<div style="width: 100%;display: flex;margin-bottom: 10px;margin-top: 20px;">
						<div>文单附件列表：</div>
						<div style="width: 80%;">
							<div v-for="(item2,index2) in info.fj" :key="index2" style="color: #409EFF;margin-bottom: 5px;cursor: pointer;width: 80%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" @click="open(item2.fullurl)">
								{{item2.filename}}
							</div>
						</div>
					</div>
					<div style="width: 100%;display: flex;margin-bottom: 10px;">
						<div>办理结果附件：</div>
						<div style="width: 80%;">
							<div v-for="(item2,index2) in info.cb" :key="index2" style="color: #409EFF;margin-bottom: 5px;cursor: pointer;width: 80%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" @click="open(item2.blfj.fullurl)">
								{{item2.blfj.filename}}
							</div>
						</div>
					</div>
					<div style="width: 100%;margin-bottom: 10px;">
						抄送：{{info.csr.length>=1?info.csr.map((item)=>{return item.name}).join(','):'无'}}
					</div>
				</div>
			</div>
			<div class="btnbox">
				<el-button type="" size="mini" class="font14" @click="dialogVisible=false">关闭</el-button>
				<el-button v-show="showprint" type="success" size="mini" @click="print">打 印</el-button>
			</div>
		</el-dialog>

	</div>

</template>

<script>
	export default {
		props:{
			showprint:{
				type:Boolean,
				defaut:false
			}
		},
		data() {
			return {
				dialogVisible: false,
				info:''
			}
		},
		methods: {
			open(url){
				window.open(url,'_blank')
			},
			print(){
				this.$printPage(this.$refs.printbox)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.tlename {
			width: 120px;
			font-size: 14px;

			text-align: right;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;
		max-height: 80vh;
		
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 0;
		border-top: 1px solid #eee;
	}

	

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
	.innerbox{
		width: 100%;
		background-color: #fff;
		padding: 20px 10px;
	}
	.title{
		font-size: 22px;
		color: #333;
		width: 100%;
		text-align: center;
	}
	.line{
		display: flex;
		align-items: center;
		height: 50px;
		width: 100%;
	}
	.namebox{
		height: 100%;
		width: 12%;
		border: 1px solid #333;
		padding: 7px;
		text-align: center;
		border-right: none;
		border-bottom: none;
		display: flex;
		align-items: center;
		justify-content: center;
		span{
			width: 100%;
		}
	}
	.namebox:nth-last-child(1){
		border-right: 1px solid #333;
	}
	.value{
		height: 100%;
		width: 35%;
		padding:7px;
		text-align: center;
		border: 1px solid #333;
		word-wrap: break-word;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: none;
		span{
			width: 100%;
			
		}
	}
	.value:nth-child(2){
		border-right: none;
	}
	.line:nth-last-child(1){
		border-bottom: 1px solid #333;
	}
	.btm_btn{
		position: fixed;
		bottom: 0;
		width: 100vw;
		left: 0;
		padding: 10px 20px;
		background-color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.tablebox{
		margin-top: 15px;
	}
</style>